import React, { useState, useEffect, useRef, createRef, useCallback, useMemo } from 'react';
import axios from "axios";
//https://react-data-table-component.netlify.app/?path=/docs/getting-started-examples--docs
import DataTable, { ExpanderComponentProps, defaultThemes } from 'react-data-table-component';

//https://react-bootstrap.netlify.app/docs/forms/input-group
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Switch from 'react-bootstrap/Switch';
import InputGroup from 'react-bootstrap/InputGroup';
import styled from 'styled-components';
import './account.css';

const TextField = styled.input`
	height: 32px;
	width: 350px;
	border-radius: 3px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #e5e5e5;
	padding: 0 32px 0 16px;
	&:hover {
		cursor: pointer;
	}
`;

const ClearButton = styled(Button)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	border-color: lightgrey;
	height: 34px;
	width: 64px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	color:darkgrey;
	&:hover {
		cursor: pointer;
	}
`;

const StyledInputGroupText = styled(InputGroup.Text)`
  width: 100px;  // Set fixed width for all labels
  display: flex;
  justify-content: flex-start; // Align text to left
`;

export default function Account() {

    const [checkboxRef, setcheckboxRef] = useState(false);
    const [account, setaccount] = useState();
    const [password, setpassword] = useState();
    const [accountdata, setAccountdata] = useState([]);
    const [data, setData] = useState([]);
    const [accountid, setAccountid] = useState();
    const [usergroup, setUsergroup] = useState([]);
    const [headertext, setHeadertext] = useState('New account')
    const [saveButtonAvailable, setSaveButtonAvailable] = useState(false)
    const [selectedRows, setSelectedRows] = useState([]);
    const [IsChangePassword, setIsChangePassword] = useState(false);
    const [dbusergroup, setDbusergroup] = useState('');
    const [auth, setAuth] = useState();
    const [datainterseptor, setDatainterseptor] = useState([]);
    const [loading, setLoading] = useState(false);


    const mailRef = useRef('');
    const pushRef = useRef();
    const pagerRef = useRef('');
    const cabRef = useRef('');
    const mobileRef = useRef('');
    const accountRef = useRef('');
    const passwordRef = useRef('');
    const newpasswordRef = useRef('');
    const accountidRef = useRef('');

    const regExp = /[a-zA-Z]/g;

    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows);
    };

    const [isAdminSwitchOn, setIsAdminSwitchOn] = useState(false);
    const onAdminSwitchAction = () => {
        setIsAdminSwitchOn(!isAdminSwitchOn);
    };

    const [isActiveSwitchOn, setIsActiveSwitchOn] = useState(false);
    const onActiveSwitchAction = () => {
        setIsActiveSwitchOn(!isActiveSwitchOn);
    };

    const [isMailSwitchOn, setIsMailSwitchOn] = useState(false);
    const onMailSwitchAction = () => {
        setIsMailSwitchOn(!isMailSwitchOn);
    };

    const [isPushSwitchOn, setIsPushSwitchOn] = useState(false);
    const onPushSwitchAction = () => {
        setIsPushSwitchOn(!isPushSwitchOn);
    };

    const [isMobileSwitchOn, setIsMobileSwitchOn] = useState(false);
    const onMobileSwitchAction = () => {
        setIsMobileSwitchOn(!isMobileSwitchOn);
    };

    const [isPagerSwitchOn, setIsPagerSwitchOn] = useState(false);
    const onPagerSwitchAction = () => {
        setIsPagerSwitchOn(!isPagerSwitchOn);
    };

    useEffect(() => {
        fetchAccountdevices()
        fetchaccounts();
        usergroups();
        const items = JSON.parse(localStorage.getItem('token'));
        if (items) {
            setAccountid(items.id);
        }
    }, []);

    const fixedHeaderScrollHeight = '1150px'
    const fixedHeader = false;
    const dence = true;

    const customStyles = {
        header: {
            style: {
                minHeight: '56px',
            },
        },
        headRow: {
            style: {
                borderTopStyle: 'solid',
                borderTopWidth: '1px',
                borderTopColor: defaultThemes.default.divider.default,
            },
        },
        headCells: {
            style: {
                '&:not(:last-of-type)': {
                    borderRightStyle: 'transparant',
                    borderRightWidth: '1px',
                    borderRightColor: defaultThemes.default.divider.default,
                },
            },
        },
        cells: {
            style: {
                '&:not(:last-of-type)': {
                    borderRightStyle: 'transparant',
                    borderRightWidth: '1px',
                    borderRightColor: defaultThemes.default.divider.default,
                },
            },
        },
    };

    const fetchAccountdevices = async page => {
        const items = JSON.parse(localStorage.getItem('token'));
        setLoading(false);
        const response = await axios.post(`https://interceptor.skylab.nl:2807/interceptor`,
            { accountid: items.id }
        );

        setDatainterseptor(response.data);
        setLoading(false);
    };

    const paginationComponentOptions = {
        selectAllRowsItem: 'true',
        selectAllRowsItemText: 'All',
        paginationPerPage: 30
    };
    const FilteringBLE_Interceptor = () => {

        const [filterText, setFilterText] = useState('');
        const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
        const filteredItems = data.filter(
            item =>
                (item.accountname && item.accountname.toLowerCase().includes(filterText.toLowerCase())) ||
                (item.email && item.email.toLowerCase().includes(filterText.toLowerCase())) ||
                (item.pushsaver && item.pushsaver.toLowerCase().includes(filterText.toLowerCase())),
        );

        const subHeaderComponentMemo = useMemo(() => {
            const handleClear = () => {
                if (filterText) {
                    setResetPaginationToggle(!resetPaginationToggle);
                    setFilterText('');
                }
            };

            return (
                <FilterComponent_Account onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
            );
        }, [filterText, resetPaginationToggle]);

        return (
            <>
                <DataTable
                    columns={columns_Accounts}
                    data={filteredItems}
                    progressPending={loading}
                    pagination
                    paginationComponentOptions={paginationComponentOptions}
                    conditionalRowStyles={conditionalRowStyles}
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    persistTableHead
                    highlightOnHover
                    customStyles={customStyles}
                    fixedHeader={fixedHeader}
                    fixedHeaderScrollHeight={fixedHeaderScrollHeight}
                    dence={dence}
                    paginationPerPage={20}
                    defaultSortFieldId="Imei"
                />
            </>
        );
    };
    const FilterComponent_Account = ({ filterText, onFilter, onClear }) => (
        <>
            <TextField
                id="search"
                type="text"
                placeholder="Filter on Name, Email or pushsaver."
                aria-label="Search Input"
                value={filterText}
                onChange={onFilter}
            />
            <ClearButton type="button" onClick={onClear}>Clear</ClearButton>
        </>
    );

    async function checkInputfields() {
        //Check fields
        if (selectedRows.length === 0) {
            alert('Please select a group');
            return false;
        }
        if (accountRef.current.value === '') {
            alert('Please fill a name');
            return false;
        }
        if (passwordRef.current === '') {
            alert('Please fill a password');
            return false;
        }
        if (pushRef.current.value.length !== 5) {
            if (isPagerSwitchOn) {
                alert('Please enter 5 digit pushsaver number');
                return false;
            }
        }
        if (regExp.test(pushRef.current.value)) {
            alert('Pushsaver are numbers only.')
            return false;
        }
        return true;
    }

    async function handleAccountAction(accountAction) {
        //Get accountid from local storage
        return fetch('https://interceptor.skylab.nl:2807/accountaction', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ accountid: accountid, action: accountAction })
        })
            .then(function (response) {

                return response.text();
            })
            .catch(function (error) {
                console.error(error);
            })
    };

    const conditionalRowStyles = [
        {
            when: row => row.active,
            style: {
                backgroundColor: 'lightgreen',
            },
        },
        {
            when: row => !row.active,
            style: {
                backgroundColor: 'lightgrey',
            },
        }
    ];

    const columns_Accounts = [
        {
            name: 'Accountname',
            selector: row => row.accountname,
            sortable: true,
            Alignment: 'left',
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            Alignment: 'left',
        },
        {
            name: 'Pushsaver',
            selector: row => row.pushsaver,
            sortable: true,
            Alignment: 'left',
        },
        {
            name: 'Mobile',
            selector: row => row.mobile,
            sortable: true,
            Alignment: 'left',
        },
        // {
        //     name: 'Pager',
        //     selector: row => row.pager,
        //     sortable: true,
        //     Alignment: 'left',
        // },
        {
            name: 'In group(s)',
            selector: row => row.usergroupid.toString(),
            sortable: true,
            center: 'true',
        },
        {
            name: 'Administrator',
            selector: row => <input type="checkbox" readOnly checked={row.administrator} />,
            sortable: true,
            center: 'true',
        },
        {
            name: 'Active',
            selector: row => <input type="checkbox" readOnly checked={row.active} />,
            sortable: true,
            center: 'true',
        },
        {
            name: 'Use pushsaver',
            selector: row => <input type="checkbox" readOnly checked={row.usepushsaver} />,
            sortable: true,
            center: 'true',
        },
        {
            button: 'true',
            cell: (row) => (
                <Button variant="warning"
                    onClick={(e) => {
                        handleEditButtonClick(e, row.bleaccountid)
                    }}>
                    Edit
                </Button>
            )
        },
        {
            button: 'true',
            cell: (row) => (
                <Button variant="warning"
                    onClick={(e) => {
                        const confirmBox = window.confirm(
                            "Do you really want to delete this account?"
                        )
                        if (confirmBox === true) {
                            handleDeleteButtonClick(e, row.bleaccountid)
                        }
                    }}>
                    Delete
                </Button>
            )
        },
        {
            button: 'true',
            cell: (row) => (
                <Button variant="warning"
                    onClick={(e) => {
                        const confirmBox = window.confirm(
                            "Do you really want to change te state of this account?"
                        )
                        if (confirmBox === true) {
                            handleActiveAccount(row.bleaccountid, row.active)
                        }
                    }}>
                    {row.active ? 'Deactivate' : 'Activate'}
                </Button>
            )
        },
        {
            name: 'id',
            selector: (row) => row.bleaccountid,
            width: '0',
        }

    ];

    const columns_Group = [
        {
            name: 'Group',
            selector: row => row.usergroupname,
            sortable: true,
            Alignment: 'left',
        },
        {
            name: 'usergroupid',
            selector: (row) => row.usergroupid,
            width: '0',
        }
    ];

    const rowSelectCriteria = useCallback(
        row => dbusergroup.toString().search(row.usergroupid) > -1,
        [dbusergroup]
    );

    const fetchaccounts = async () => {
        const response = await axios.get(`https://interceptor.skylab.nl:2807/accounts`,
            {
                headers: {
                    'gfg_jwt_secret_key': auth
                }
            });
        setData(response.data);
    };

    const usergroups = async () => {
        const response = await axios.get(`https://interceptor.skylab.nl:2807/usergroup`,
            {
                headers: {
                    'gfg_jwt_secret_key': auth
                }
            });
        setUsergroup(response.data);
    };

    async function handleActiveAccount(id, currentstate) {
        return fetch('https://interceptor.skylab.nl:2807/activeaccount', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'gfg_jwt_secret_key': auth
            },
            body: JSON.stringify({ bleaccountid: id, active: currentstate })
        })
            .then(function (response) {

                if (response.status === 200) {
                    fetchaccounts();
                }

                handleAccountAction('De/Activate account:' + id + ' to State:' + !currentstate)

                return response.text();
            })
            .catch(function (error) {
                console.error(error);
            })
    };

    async function handleDeleteButtonClick(e, id) {
        e.preventDefault();
        return fetch('https://interceptor.skylab.nl:2807/delaccount', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'gfg_jwt_secret_key': auth
            },
            body: JSON.stringify({ accountid: id })
        })
            .then(function (response) {

                if (response.status === 200) {
                    fetchaccounts();
                }

                handleAccountAction('Delete account:' + id)

                return response.text();
            })
            .catch(function (error) {
                console.error(error);
            })
    };

    const handleEditButtonClick = async (e, id) => {
        setHeadertext('Account overview');
        setSaveButtonAvailable(true)
        setIsChangePassword(true)
        try {
            const response = await fetch('https://interceptor.skylab.nl:2807/getaccount', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'gfg_jwt_secret_key': auth
                },
                body: JSON.stringify({ accountid: id })
            }); // Replace with your API endpoint
            if (!response.ok) {
                console.log('Network response was not ok.');
            }
            const data = await response.json();
            accountidRef.current.value = data[0].bleaccountid;
            accountRef.current.value = data[0].accountname;
            mailRef.current.value = data[0].email;

            pushRef.current.value = data[0].pushsaver;
            mobileRef.current.value = data[0].mobile;
            setDbusergroup(data[0].usergroupid);
            setIsAdminSwitchOn(data[0].administrator);
            setIsActiveSwitchOn(data[0].active);
            setIsPushSwitchOn(data[0].usepushsaver)
        } catch (error) {
            console.log(error)
        }
    };

    async function addAccount(credentials) {
        return fetch('https://interceptor.skylab.nl:2807/addaccount', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'gfg_jwt_secret_key': auth
            },
            body: JSON.stringify(credentials)
        })
            .then(function (response) {

                if (response.status === 200) {
                    fetchaccounts();
                    handleAccountAction('Added account:' + credentials.account)
                }
                else if (response.status === 599) {
                    const confirmBox = window.confirm(
                        'Account already in the list.'
                    )
                }
                return response.text();
            })
            .catch(function (error) {
                console.error(error);
            })
    }

    async function updateAccount(credentials) {
        return fetch('https://interceptor.skylab.nl:2807/accountupdate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'gfg_jwt_secret_key': auth
            },
            body: JSON.stringify(credentials)
        })
            .then(function (response) {

                if (response.status === 200) {
                    fetchaccounts();
                    handleAccountAction('Edit account:' + credentials.account)
                }
                return response.text();
            })
            .catch(function (error) {
                console.error(error);
            })
    }

    const handleUpdateAccount = async e => {
        var result = await checkInputfields()
        if (!result)
            return;

        e.preventDefault();
        await updateAccount({
            accountid: accountidRef.current.value,
            account: accountRef.current.value,
            email: mailRef.current.value,
            pushsaver: pushRef.current.value,
            mobile: mobileRef.current.value,
            administrator: isAdminSwitchOn,
            active: isActiveSwitchOn,
            usergroup: selectedRows,
            usepushsaver: isPushSwitchOn,

            // pager: pagerRef.current.value,
            // cab: cabRef.current.value,
            // password: passwordRef.current.value,
            // usemail: isMailSwitchOn,
            // usemobile: isMobileSwitchOn,
            // usepager: isPagerSwitchOn
        });
    }

    const handleAddAccount = async e => {
        var result = await checkInputfields()
        if (!result)
            return;

        e.preventDefault();
        await addAccount({
            account: accountRef.current.value,
            password: passwordRef.current.value,
            email: mailRef.current.value,
            pushsaver: pushRef.current.value,
            mobile: mobileRef.current.value,
            pager: pagerRef.current.value,
            cab: cabRef.current.value,
            administrator: isAdminSwitchOn,
            active: isActiveSwitchOn,
            usemail: isMailSwitchOn,
            usepushsaver: isPushSwitchOn,
            usemobile: isMobileSwitchOn,
            usepager: isPagerSwitchOn,
            usergroup: selectedRows
        });
    }



    return (
        <>
            <div className='cForm'>

                <div className='cAccount'>
                    <Form.Label className='cLabel'>{headertext}</Form.Label>
                </div>

                <InputGroup className="mb-2">
                    <StyledInputGroupText id="basic-addon1">Name</StyledInputGroupText>
                    <Form.Control
                        required={true}
                        placeholder="Account name"
                        aria-label="Account name"
                        aria-describedby="basic-addon1"
                        ref={accountRef}
                    />
                </InputGroup>

                {!saveButtonAvailable && <InputGroup className="mb-2">
                    <StyledInputGroupText id="basic-addon1">Password</StyledInputGroupText>
                    <Form.Control
                        required={true}
                        placeholder="Password"
                        aria-label="Password"
                        aria-describedby="basic-addon1"
                        ref={passwordRef}
                    />
                </InputGroup>}

                <InputGroup className="mb-2">
                    <StyledInputGroupText id="basic-addon1">Email</StyledInputGroupText>
                    <Form.Control
                        type="email"
                        placeholder="Email"
                        aria-label="Email"
                        aria-describedby="basic-addon1"
                        ref={mailRef}
                    />
                    <Switch className='cSwitch'
                        onChange={onMailSwitchAction}
                        checked={isMailSwitchOn}
                        type="switch"
                        id="custom-switch"
                        label=""
                        reverse
                    />
                </InputGroup>

                <InputGroup className="mb-2">
                    <StyledInputGroupText id="basic-addon1">Pushsaver</StyledInputGroupText>
                    <Form.Control
                        type="text"
                        placeholder="Pushsaver"
                        aria-label="Pushsaver"
                        aria-describedby="basic-addon1"
                        ref={pushRef}
                    />
                    <Switch className='cSwitch'
                        onChange={onPushSwitchAction}
                        checked={isPushSwitchOn}
                        type="switch"
                        id="custom-switch"
                        label="Active"
                        reverse
                    />
                </InputGroup>

                <InputGroup className="mb-2">
                    <StyledInputGroupText id="basic-addon1">Mobile</StyledInputGroupText>
                    <Form.Control
                        type="text"
                        placeholder="Mobile"
                        aria-label="Mobile"
                        aria-describedby="basic-addon1"
                        ref={mobileRef}
                    />
                    <Switch className='cSwitch'
                        onChange={onMobileSwitchAction}
                        checked={isMobileSwitchOn}
                        type="switch"
                        id="custom-switch"
                        label=""
                        reverse
                    />
                </InputGroup>

                {false && <InputGroup className="mb-2">
                    <StyledInputGroupText id="basic-addon1">Pager</StyledInputGroupText>
                    <Form.Control
                        type="number"
                        placeholder="Pager"
                        aria-label="Pager"
                        aria-describedby="basic-addon1"
                        ref={pagerRef}
                    />
                    <Switch className='cSwitch'
                        onChange={onPagerSwitchAction}
                        checked={isPagerSwitchOn}
                        type="switch"
                        id="custom-switch"
                        label=""
                        reverse
                    />
                </InputGroup>}

                {false && <InputGroup className="mb-2">
                    <StyledInputGroupText id="basic-addon1">Cab</StyledInputGroupText>
                    <Form.Control
                        type="number"
                        placeholder="Cab"
                        aria-label="Cab"
                        aria-describedby="basic-addon1"
                        ref={cabRef}
                    />
                </InputGroup>}

                <Switch className='cSwitch'
                    onChange={onAdminSwitchAction}
                    checked={isAdminSwitchOn}
                    type="switch"
                    id="custom-switch"
                    label="Administrator"
                    reverse
                />

                <Switch className='cSwitch'
                    onChange={onActiveSwitchAction}
                    checked={isActiveSwitchOn}
                    type="switch"
                    id="custom-switch"
                    label="Active"
                    reverse
                />

                {true && <div>
                    <DataTable
                        columns={columns_Group}
                        data={usergroup}
                        selectableRows
                        onSelectedRowsChange={handleChange}
                        selectableRowsHighlight={true}
                        selectableRowSelected={rowSelectCriteria}
                    ></DataTable>
                </div>}

                <Button onClick={handleAddAccount}
                    hidden={saveButtonAvailable}>
                    Add this account
                </Button>

                <Button onClick={handleUpdateAccount}
                    hidden={!saveButtonAvailable}>
                    Save
                </Button>

            </div>

            <input name="accountid"
                ref={accountidRef}
                hidden={true}
            ></input>

            {true && <div className='cTable'>
                <div>
                    <Form.Label className='cLabel' htmlFor="inputPassword5">Account overview</Form.Label>
                </div>
                <FilteringBLE_Interceptor></FilteringBLE_Interceptor>
            </div>}
        </>
    );
}