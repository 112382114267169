import React, { useState } from 'react';
import { MapContainer } from 'react-leaflet/MapContainer'
import { TileLayer } from 'react-leaflet/TileLayer'
import { useMap } from 'react-leaflet/hooks'
import { Marker } from 'react-leaflet/Marker'
import { Popup } from 'react-leaflet/Popup'
import 'leaflet/dist/leaflet.css';
import './mapbase.css';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41]
});
L.Marker.prototype.options.icon = DefaultIcon;

function RecenterAutomatically({ lat, lng }) {
  const map = useMap();
  React.useEffect(() => {
    map.setView([lat, lng]);
  }, [lat, lng, map]);
  return null;
}

function calculateBounds(iconPosition) {
  if (!iconPosition || iconPosition.length === 0) return null;

  // Validate coordinates first
  const validMarkers = iconPosition.filter(marker =>
    marker.lat && marker.long &&
    !isNaN(marker.lat) && !isNaN(marker.long) &&
    Math.abs(marker.lat) <= 90 && Math.abs(marker.long) <= 180
  );

  if (validMarkers.length === 0) return null;

  // Initialize with first valid position
  let maxN = validMarkers[0].lat;
  let maxS = validMarkers[0].lat;
  let maxE = validMarkers[0].long;
  let maxW = validMarkers[0].long;

  // Find bounds
  validMarkers.forEach(marker => {
    maxN = Math.max(maxN, marker.lat);
    maxS = Math.min(maxS, marker.lat);
    maxE = Math.max(maxE, marker.long);
    maxW = Math.min(maxW, marker.long);
  });

  // Add padding to bounds (about 10% on each side)
  const latPadding = (maxN - maxS) * 0.1;
  const lngPadding = (maxE - maxW) * 0.1;

  maxN += latPadding;
  maxS -= latPadding;
  maxE += lngPadding;
  maxW -= lngPadding;

  return {
    bounds: {
      ne: { lat: maxN, long: maxE },
      sw: { lat: maxS, long: maxW }
    },
    center: {
      lat: (maxN + maxS) / 2,
      long: (maxE + maxW) / 2
    }
  };
}

function FitBounds({ bounds }) {
  const map = useMap();

  React.useEffect(() => {
    if (bounds && bounds.ne && bounds.sw) {
      // Set bounds with padding
      map.fitBounds([
        [bounds.ne.lat, bounds.ne.long],
        [bounds.sw.lat, bounds.sw.long]
      ], {
        padding: [50, 50], // Add 50 pixels padding
        maxZoom: 18 // Limit maximum zoom level
      });
    }
  }, [bounds, map]);

  return null;
}

export default function MapBase({ iconPosition }) {
  const [userInteraction, setUserInteraction] = React.useState(false);
  const [lastValidPosition, setLastValidPosition] = useState([51.505, -0.09]);
  const [lastValidPopperContent, setLastValidPopperContent] = useState('No data available');
  const [lastValidData, setLastValidData] = useState({
    udi: 'N/A',
    version: 'N/A',
    status: 'N/A',
    heartbeat: 'N/A',
    alt: 'N/A',
    spd: 'N/A',
    fix: 'N/A',
    sats: 'N/A',
    gpstime: 'N/A',
    gsm: 'N/A',
    ble_cnt: 'N/A',
    acc: 'N/A',
    voltage: 'N/A',
    batt: 'N/A'
  });


  const boundsData = React.useMemo(() => calculateBounds(iconPosition), [iconPosition]);

  // Update initial position calculation with validation
  const initialPosition = iconPosition?.[0]?.lat && iconPosition[0]?.long &&
    !isNaN(iconPosition[0].lat) && !isNaN(iconPosition[0].long) &&
    Math.abs(iconPosition[0].lat) <= 90 && Math.abs(iconPosition[0].long) <= 180
    ? [iconPosition[0].lat, iconPosition[0].long]
    : lastValidPosition;
  // Update lastValidPosition when we get a valid iconPosition
  const position = iconPosition?.[0]?.lat && iconPosition[0]?.long
    ? [iconPosition[0].lat, iconPosition[0].long]
    : lastValidPosition;

  // Create popup content from iconPosition data
  const currentPopperContent = iconPosition?.[0] ? (
    <div>
      <h4>Device Info</h4>
      <p><strong>ID:</strong> {iconPosition[0].udi ? iconPosition[0].udi : lastValidData.udi}</p>
      <p><strong>Status:</strong> {iconPosition[0].status ? iconPosition[0].status : lastValidData.status}</p>
      <p><strong>Heartbeat:</strong> {iconPosition[0].heartbeat ? iconPosition[0].heartbeat : lastValidData.heartbeat}</p>
      <p><strong>Version:</strong> {iconPosition[0].version ? iconPosition[0].version : lastValidData.version}</p>

      <h4>Location</h4>
      <p><strong>Latitude:</strong> {iconPosition[0].lat ? iconPosition[0].lat : lastValidPosition.lat}°</p>
      <p><strong>Longitude:</strong> {iconPosition[0].long ? iconPosition[0].long : lastValidPosition.long}°</p>
      <p><strong>Altitude:</strong> {iconPosition[0].alt ? iconPosition[0].alt : lastValidData.alt} m</p>
      <p><strong>Speed:</strong> {iconPosition[0].spd ? iconPosition[0].spd : lastValidData.spd} km/h</p>

      <h4>Signal Info</h4>
      <p><strong>GPS Fix:</strong> {iconPosition[0].fix ? iconPosition[0].fix : lastValidData.fix}</p>
      <p><strong>Satellites:</strong> {iconPosition[0].sats ? iconPosition[0].sats : lastValidData.sats}</p>
      {/* <p><strong>GPS Time:</strong> {iconPosition[0].gpstime ? iconPosition[0].gpstime : lastValidData.gpstime}</p> */}
      <p><strong>GSM Signal:</strong> {iconPosition[0].gsm ? iconPosition[0].gsm : lastValidData.gsm}</p>
      <p><strong>BLE Devices:</strong> {iconPosition[0].ble_cnt ? iconPosition[0].ble_cnt : lastValidData.ble_cnt}</p>
      <p><strong>Accuracy:</strong> {iconPosition[0].acc ? iconPosition[0].acc : lastValidData.acc} m</p>

      <h4>Power Info</h4>
      <p><strong>Battery:</strong> {iconPosition[0].batt ? iconPosition[0].batt : lastValidData.batt} %</p>
      <p><strong>Voltage:</strong> {iconPosition[0].voltage ? iconPosition[0].voltage : lastValidData.voltage} Volt</p>
    </div>
  ) : lastValidPopperContent;

  // Store the last valid values
  React.useEffect(() => {
    if (iconPosition?.[0]) {
      const newValidData = {};
      Object.keys(lastValidData).forEach(key => {
        const newValue = iconPosition[0][key];

        // Keep previous valid value if new value is 0 or null
        if (newValue != null && newValue !== 0) {
          newValidData[key] = newValue;
        }
      });

      if (Object.keys(newValidData).length > 0) {
        setLastValidData(prev => ({ ...prev, ...newValidData }));
      }
    }
  }, [iconPosition]);

  // Store the new position if it's valid
  React.useEffect(() => {
    if (iconPosition?.[0]?.lat && iconPosition[0]?.long) {
      setLastValidPosition([iconPosition[0].lat, iconPosition[0].long]);
    }
  }, [iconPosition]);


  // Add event handlers for map
  const HandleMapEvents = () => {
    const map = useMap();

    React.useEffect(() => {
      const onMoveOrZoom = () => {
        setUserInteraction(true);
      };

      map.on('movestart', onMoveOrZoom);
      map.on('zoomstart', onMoveOrZoom);

      // Cleanup event listeners
      return () => {
        map.off('movestart', onMoveOrZoom);
        map.off('zoomstart', onMoveOrZoom);
      };
    }, [map]);

    return null;
  };

  // Add click handler for Autozoom button
  const handleAutozoom = () => {
    setUserInteraction(false);
  };

  return (
    <div>
      {userInteraction && <button
        id="btnAutozoom"
        className="btn btn-primary"
        onClick={handleAutozoom}
      >
        Set back to autozoom
      </button>}

      <div className="map-container">
        <MapContainer center={initialPosition} zoom={13} scrollWheelZoom={true}>
          {!userInteraction && boundsData && <FitBounds bounds={boundsData.bounds} />}
          {!userInteraction && <RecenterAutomatically lat={initialPosition[0]} lng={initialPosition[1]} />}
          <HandleMapEvents />
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={position}>
            <Popup>
              {currentPopperContent}
            </Popup>
          </Marker>
        </MapContainer>
      </div>
    </div>
  );
}