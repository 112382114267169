import React from 'react';
import './App.css';
import Dashboard from './dashboard';
import Login from './login';
import useToken from './useToken';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme(); // You can customize the theme here

function App() {

 const { token, setToken } = useToken();

  if(!token) {
    return <Login setToken={setToken} />
  }

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <div className="wrapper">
          <Dashboard />
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;