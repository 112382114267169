import './dashboard.css';

import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

//https://react-data-table-component.netlify.app/?path=/docs/getting-started-examples--docs
import DataTable, { ExpanderComponentProps, defaultThemes } from 'react-data-table-component';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleUp, faMap } from '@fortawesome/free-regular-svg-icons';

//https://www.npmjs.com/package/react-tabs
//https://newyork-anthonyng.github.io/gitbooks_test/activetabclassname.html
//https://reactcommunity.org/react-tabs/
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Historymap from "./historymap.js"
import Maprt from "./maprt.js"
import MultiMap from "./multimap.js"
import Account from './account.js';
import Interceptor from './interceptor.js';
import Whitelist from './whitelist.js';
import Groups from './groups.js';
import Passwordchange from './pwdchange.js';
import BarChart from './barchart.js';
import mqtt from 'mqtt';
import AirTagDecoder from './AirTagDecoder.js';
import Brokerstatus from './brokerstatus.js';
import Followmac from './followmac.js';

const ClearButton = styled(Button)`
border-top-left-radius: 0;
border-bottom-left-radius: 0;
border-top-right-radius: 5px;
border-bottom-right-radius: 5px;
border-color: lightgrey;
height: 34px;
width: 64px;
text-align: center;
display: flex;
align-items: center;
justify-content: center;
color:darkgrey;
&:hover {
	cursor: pointer;
}
`;

const TextField = styled.input`
height: 32px;
width: 350px;
border-radius: 3px;
border-top-left-radius: 5px;
border-bottom-left-radius: 5px;
border-top-right-radius: 0;
border-bottom-right-radius: 0;
border: 1px solid #e5e5e5;
padding: 0 32px 0 16px;
&:hover {
	cursor: pointer;
}
`;
export default function Dashboard() {

	const [isadmin, setIsadmin] = useState();
	const [user, setUser] = useState();
	const [accountid, setAccountid] = useState();
	const [udicontent, setUdicontent] = useState([]);
	const [manufacturers, setManufacturers] = useState([]);
	const [accountdevices, setAccountdevices] = useState([]);
	const [activeTab, setActiveTab] = useState(0);
	const navigate = useNavigate();
	const [followMac, setFollowMac] = useState(false);

	const clearCacheData = () => {
		caches.keys().then((names) => {
			names.forEach((name) => {
				caches.delete(name);
			});
		});
		console.log("Complete Cache Cleared");
	};

	function calculateBatteryPercentage(voltage) {
		const maxVoltage = 4.2;
		const minVoltage = 3.5;

		if (voltage >= maxVoltage) {
			return 100;
		} else if (voltage <= minVoltage) {
			return 0;
		} else {
			// Lineaire interpolatie om het percentage te berekenen
			return (((voltage - minVoltage) / (maxVoltage - minVoltage)) * 100).toFixed(0);
		}
	}

	useEffect(() => {
		const items = JSON.parse(localStorage.getItem('token'));
		if (items) {
			setIsadmin(items.admin);
			setUser(items.token)
			setAccountid(items.id)
			fetchmanufacturers();
			fetchaccountdevices(items.id);
			setFollowMac(!!items.followMac);
			fetchudi();
		}
		clearCacheData();
		const handleTabClose = (event) => {
			logout();
		};

		window.addEventListener('beforeunload', handleTabClose);

		return () => {
			window.removeEventListener('beforeunload', handleTabClose);
		};
	}, []);

	const conditionalRowStyles = [
		{
			when: row => row.status === 'online',
			style: {
				backgroundColor: 'rgba(63, 195, 128, 0.9)',
			},
		},
		{
			when: row => row.status === 'offline',
			style: {
				backgroundColor: 'lightgrey',
			},
		},
		{
			when: row => row.active === false,
			style: {
				backgroundColor: 'salmon',
			},
		}
	];

	const conditionalRowStyles_rt = [
		{
			when: row => row.status === 0 || row.status === 1,
			style: {
				backgroundColor: 'lightgreen',
			},
		},
		{
			when: row => row.status === 255,
			style: {
				backgroundColor: 'lightgrey',
			},
		}
	];

	const FilterComponentInterceptor = ({ filterText, onFilter, onClear }) => (
		<>
			<TextField
				id="search"
				type="text"
				placeholder="Filter on Imei, Name or Seal."
				aria-label="Search Input"
				value={filterText}
				onChange={onFilter}
			/>
			<ClearButton type="button" onClick={onClear}>Clear</ClearButton>
		</>
	);

	const FilterComponent = ({ filterText, onFilter, onClear }) => (
		<>
			<TextField
				id="search"
				type="text"
				placeholder="Filter on Imei, Mac, Name or Manufacturer"
				aria-label="Search Input"
				value={filterText}
				onChange={onFilter}
			/>
			<ClearButton type="button" onClick={onClear}>Clear</ClearButton>
		</>
	);

	//https://react-data-table-component.netlify.app/?path=/story/pagination-remote--remote

	const ExpandableRowComponent = ({ data }) => {
		return (
			<>
				<FilteringBLE_RT
					imei={data.udi}>
				</FilteringBLE_RT>
			</>
		);
	};

	const customStyles = {
		header: {
			style: {
				minHeight: '56px',
			},
		},
		headRow: {
			style: {
				borderTopStyle: 'solid',
				borderTopWidth: '1px',
				borderTopColor: defaultThemes.default.divider.default,
			},
		},
		headCells: {
			style: {
				'&:not(:last-of-type)': {
					borderRightStyle: 'transparant',
					borderRightWidth: '1px',
					borderRightColor: defaultThemes.default.divider.default,
				},
			},
		},
		cells: {
			style: {
				'&:not(:last-of-type)': {
					borderRightStyle: 'transparant',
					borderRightWidth: '1px',
					borderRightColor: defaultThemes.default.divider.default,
				},
			},
		},
	};

	const columns_Interceptor = [
		{
			name: 'Last update',
			selector: row => moment(new Date()).format('HH:mm:ss DD-MM-YY'),
			sortable: true,
			center: true
		},
		{
			name: 'Imei',
			selector: row => row.udi,
			sortable: true,
			center: true
		},
		{
			name: 'Seal',
			selector: row => row.seal,
			sortable: true,
			center: true
		},
		{
			name: 'Name',
			selector: row => row.devicename,
			sortable: true,
		},
		{
			name: 'Devices',
			selector: row => row.ble_cnt,
			sortable: true,
			center: true
		},
		{
			name: 'Signal',
			selector: row => row.gsm,
			sortable: true,
			center: true
		},
		{
			name: 'Fix',
			selector: row => row.fix,
			sortable: true,
			center: true
		},
		{
			name: 'Satellites',
			selector: row => row.sats,
			sortable: true,
			center: true
		},
		{
			name: 'Speed',
			selector: row => row.spd,
			sortable: true,
			center: true
		},
		{
			name: 'Altitude',
			selector: row => row.alt,
			sortable: true,
			center: true
		},
		{
			name: 'Acc',
			selector: row => row.acc,
			sortable: true,
			center: true
		},
		{
			name: 'Battery %',
			cell: row => row.batt >= 0 && (
				<input className='cinput'
					defaultValue={row.batt}
					title={row.voltage + ' Volt.'} />
			),
			sortable: 'false',
			center: 'true',
			width: '105px',
			conditionalCellStyles: [
				{
					when: row => row.battery >= 1 && row.battery < 10,
					style: {
						backgroundColor: 'orange',
					},
				},
				{
					when: row => row.battery === 0,
					style: {
						backgroundColor: 'red',
					},
				},
			]
		},
		{
			name: 'chargetype',
			selector: row => row.chargetype,
			center: 'true',
			sortable: 'true'
		},
		{
			name: 'Active',
			selector: row => <input type="checkbox" readOnly={true} checked={row.active} />,
			center: 'true'
		},
		{
			name: 'Alert',
			selector: row => <input type="checkbox" readOnly={true} checked={row.alertactive} />,
			center: 'true'
		},
		{
			name: 'Location',
			cell: row => (row.lat !== 0 && row.long !== 0) && (
				<a target="_blank" href={"https://www.google.nl/maps/place/" + row.latitude + "," + row.longitude} rel="noreferrer">
					<FontAwesomeIcon icon={faMap} title="Show on Google map"></FontAwesomeIcon>
				</a>
			),
			center: 'true',
		},
		{
			name: 'Priority',
			cell: row => row.prioritytype,
			omit: !isadmin,
			sortable: true,
			center: 'true'
		},
		{
			name: 'Version',
			cell: row => row.version,
			omit: !isadmin,
			sortable: true,
			center: 'true'
		}
	];

	const RSSI_COLORS = [
		{ max: -50, color: [0, 255, 0] },        // Green
		{ max: -60, color: [173, 255, 47] },     // Green-Yellow
		{ max: -70, color: [255, 255, 0] },      // Yellow
		{ max: -80, color: [255, 165, 0] },      // Orange
		{ max: -90, color: [255, 69, 0] },       // Orange-Red
		{ max: Infinity, color: [255, 0, 0] }    // Red
	];

	// Functie om de kleur te interpoleren
	const interpolateColor = (color1, color2, factor = 0.25) => {
		return color1.map((c, i) => Math.round(c + factor * (color2[i] - c)));
	};

	// Functie om de RSSI-kleur te verkrijgen
	const getRSSIColor = (rssi) => {
		for (let i = 0; i < RSSI_COLORS.length - 1; i++) {
			if (rssi <= RSSI_COLORS[i].max) {
				let next = RSSI_COLORS[i + 1];
				let factor = (rssi - next.max) / (RSSI_COLORS[i].max - next.max);
				const color = interpolateColor(next.color, RSSI_COLORS[i].color, factor);
				return 'rgb(' + color.join(",") + ')';
			}
		}
		return "rgb(255,0,0)";  // Default color als er geen match is
	};

	// Voorbeeld React component die de kleur op basis van RSSI weergeeft
	function RSSIIndicator(rssi) {
		const color = getRSSIColor(rssi);
		//console.log(color)
		return color;
	};

	const columns_rt = [
		{
			name: 'Date',
			selector: row => moment(row.lastSeen || row.firstSeen).format('HH:mm:ss DD-MM-YY'),
			sortable: true,
			width: '180px',
		},
		{
			name: 'Airtag',
			selector: row => row.decodedData ? JSON.stringify(row.decodedData) : '-',
			sortable: true,
			wrap: true,
			width: '200px',
			center: false,
		},
		{
			name: 'Imei',
			selector: row => row.udi,
			sortable: true,
			width: '180px',
		},
		{
			name: 'Mac',
			selector: row => row.mac,
			sortable: true,
			width: '180px',
		},
		{
			name: 'History',
			cell: row => (row.mac) && (
				<FontAwesomeIcon
					icon={faArrowAltCircleUp}
					title="Show history"
					onClick={() => {
						setActiveTab(3);  // Index 3 corresponds to the History Map tab
						// Store the MAC address in localStorage to pass it to the History Map component
						localStorage.setItem('selectedMac', row.mac);
					}}
					style={{ cursor: 'pointer' }}
				></FontAwesomeIcon>
			),
			center: 'true',
		},
		{
			name: 'Follow',
			cell: row => (row.udi) && (
				<FontAwesomeIcon
					icon={faArrowAltCircleUp}
					title="Follow live"
					onClick={() => {
						setActiveTab(6);  // New tab index for Follow MAC
						// Store both MAC and UDI in localStorage
						localStorage.setItem('followMac', row.mac);
					}}
					style={{ cursor: 'pointer' }}
				></FontAwesomeIcon>
			),
			center: 'true',
		},
		{
			name: 'Name',
			selector: row => row.name,
			sortable: true,
		},
		{
			name: 'Manufacturer',
			selector: row => row.manufacturer,
			sortable: true,
		},
		{
			name: 'Rssi',
			selector: row => row.rssi,
			sortable: true,
			center: true,
			width: '100px',
			conditionalCellStyles: [
				{
					when: row => row.rssi < 0,
					style: row => ({
						backgroundColor: RSSIIndicator(row.rssi),
					}),
				},
			]
		},
		{
			name: 'Time',
			selector: row => row.regtime,
			sortable: true,
			center: true
		},
		{
			name: 'System time',
			selector: row => row.onscrFeentime,
			sortable: true,
			center: true,
			cell: row => row.insystemtime,
		},
		{
			name: 'Diff time',
			selector: row => row.differcetime,
			sortable: true,
			center: true,
		},
		// {
		// 	name: 'Alert',
		// 	cell: row => row.alarm,
		// 	sortable: true,
		// 	center: true,
		// 	width: '100px',
		// 	conditionalCellStyles: [
		// 		// You can also pass a callback to style for additional customization
		// 		{
		// 			when: row => row.alarm === 1,
		// 			style: row => ({
		// 				backgroundColor: 'orange',
		// 			}),
		// 		},
		// 	]
		// },
		// {
		// 	button: 'true',
		// 	omit: !isadmin,
		// 	cell: (row, setDataMacFn) => (
		// 		<Button variant="warning"
		// 			onClick={(e) => {
		// 				const confirmBox = window.confirm(
		// 					"Do you really want to whitelist this mac?"
		// 				)
		// 				if (confirmBox === true) {
		// 					handleWhitelistMac(e, row.mac, row.name, setDataMacFn)
		// 				}
		// 			}}>
		// 			Whitelist
		// 		</Button>
		// 	)
		// },
		// {
		// 	button: 'true',
		// 	omit: true, //!isadmin,
		// 	cell: (row) => (
		// 		<Button variant="warning"
		// 		>
		// 			Rssi alert
		// 		</Button>
		// 	)
		// }
	];

	const paginationComponentOptions = {
		selectAllRowsItem: 'true',
		selectAllRowsItemText: 'All',
		paginationPerPage: 30
	};

	const fixedHeaderScrollHeight = '1150px'
	const fixedHeader = false;
	const dence = true;

	const logout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("followMac");
		localStorage.removeItem("followUdi");
		localStorage.removeItem("selectedMac");
		localStorage.removeItem("selectedUdi");
		window.location.reload();
	};

	async function fetchudi() {
		await fetch('https://interceptor.skylab.nl:2807/interceptorudi', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
		})
			.then(response => {
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				return response.json();
			})
			.then(newUserData => {
				// Process the newly created user data
				//console.log('New User Data:', newUserData);
				setUdicontent(newUserData)
			})
			.catch(error => {
				console.error('Error:', error);
			});
	};
	async function fetchmanufacturers() {
		await fetch('https://interceptor.skylab.nl:2807/manufacturers', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			},
		})
			.then(response => {
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				return response.json();
			})
			.then(newUserData => {
				// Process the newly created user data
				//console.log('New User Data:', newUserData);
				setManufacturers(newUserData)
			})
			.catch(error => {
				console.error('Error:', error);
			});
	};

	async function fetchaccountdevices(accountid) {
		try {
			const response = await fetch('https://interceptor.skylab.nl:2807/accountudidevices', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ accountid: accountid })
			});

			if (!response.ok) {
				throw new Error('Network response was not ok');
			}

			const newUserData = await response.json();
			setAccountdevices(newUserData);
			return newUserData; // Return the data for chaining
		} catch (error) {
			console.error('Error:', error);
			throw error; // Re-throw to handle in the calling function
		}
	}

	async function handleWhitelistMac(e, mac, name, setDataMacFn) {
		e.preventDefault();
		return fetch('https://interceptor.skylab.nl:2807/whitelistmac', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ mac: mac, name: name })
		})
			.then(function (response) {
				if (response.status === 200) {
					// Remove the MAC from dataMac state
					setDataMacFn(prevData => prevData.filter(row => row.mac.toLowerCase() !== mac.toLowerCase()));
				}
				if (response.status === 409) {
					alert('MAC already exists in whitelist');
				}
				handleAccountAction('Whitelist:' + mac + ' name:' + name)
			})
			.catch(function (error) {
				console.error(error);
			})
	};

	async function handleAccountAction(accountAction) {
		//Get accountid from local storage

		const items = JSON.parse(localStorage.getItem('token'));
		const accountid = items.id;

		//console.log(accountid, accountAction)

		return fetch('https://interceptor.skylab.nl:2807/accountaction', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ accountid: accountid, action: accountAction })
		})
			.then(function (response) {
				return response.text();
			})
			.catch(function (error) {
				console.error(error);
			})
	};

	const FilteringInterceptor = () => {
		const [datainterseptor, setDatainterseptor] = useState([]);
		const [loading, setLoading] = useState(false);
		const [client, setClient] = useState(null);
		const [isConnected, setIsConnected] = useState(false);

		// Memoize the message handler to prevent recreating on every render
		const onMessageArrived = useCallback((message) => {
			try {
				const topic = message.destinationName;
				const payload = message.payloadString;

				const parts = topic.split('/');
				if (parts.length >= 2) {
					const imei = parts[1];

					if (!interceptors[imei]) {
						interceptors[imei] = {
							ble_cnt: null,
							fix: null,
							acc: null,
							gpstime: null,
							version: null,
							batt: null,
							gsm: null,
							lat: null,
							long: null,
							spd: null,
							alt: null,
							sats: null,
							heartbeat: 'online'
						};
					}

					const safePayload = payload !== null && payload !== undefined ? payload : null;

					if (parts[2]) {  // Check if we have a third part in the topic
						if (parts[3]) {  // If we have a fourth part, it's a standard metric
							switch (parts[3]) {
								case 'ble_cnt': interceptors[imei].ble_cnt = safePayload; break;
								case 'fix': interceptors[imei].fix = safePayload; break;
								case 'acc': interceptors[imei].acc = safePayload; break;
								case 'gpstime': interceptors[imei].gpstime = safePayload; break;
								case 'version': interceptors[imei].version = safePayload; break;
								case 'batt': interceptors[imei].batt = safePayload; break;
								case 'gsm': interceptors[imei].gsm = safePayload; break;
								case 'lat': interceptors[imei].lat = safePayload; break;
								case 'long': interceptors[imei].long = safePayload; break;
								case 'spd': interceptors[imei].spd = safePayload; break;
								case 'alt': interceptors[imei].alt = safePayload ? (parseFloat(safePayload) / 10).toFixed(2) : null; break;
								case 'sats': interceptors[imei].sats = safePayload; break;
								case 'heartbeat': interceptors[imei].heartbeat = safePayload || 'online'; break;
							}
						}
					}

					const interceptorArray = [
						{
							udi: imei,
							// Find matching interceptor data from udicontent
							...(() => {
								const intarr = udicontent.find(item => item.udi === imei) || {};
								return {
									seal: intarr.seal || '-',
									devicename: intarr.devicename || '-',
									chargetype: intarr.chargetype || '-',
									prioritytype: intarr.prioritytype || '-',
									active: intarr.active || false,
									alert: intarr.alert || false,
									alertactive: intarr.alertactive || false,
								};
							})(),

							// MQTT data
							acc: interceptors[imei]['acc'],
							fix: interceptors[imei]['fix'],
							version: interceptors[imei]['version'],
							voltage: interceptors[imei]['batt'],
							batt: calculateBatteryPercentage((interceptors[imei]['batt'])),
							gsm: interceptors[imei]['gsm'],
							ble_cnt: interceptors[imei]['ble_cnt'],
							lat: interceptors[imei]['lat'],
							long: interceptors[imei]['long'],
							sats: interceptors[imei]['sats'],
							alt: interceptors[imei]['alt'],
							spd: interceptors[imei]['spd'],
							heartbeat: interceptors[imei]['heartbeat'],
							gpstime: interceptors[imei]['gpstime'],
							status: interceptors[imei]['heartbeat'],
						}
					];

					if (interceptorArray.length > 0) {
						setDatainterseptor(prevData => {
							const existingIndex = prevData.findIndex(item => item.udi === imei);
							if (existingIndex === -1) {
								return [...prevData, ...interceptorArray];
							}
							return prevData.map((item, index) => {
								if (index === existingIndex) {
									return interceptorArray[0];
								}
								return item;
							});
						});
					}
				}
			} catch (error) {
				console.error('Error processing MQTT message:', error);
			}
		}, []); // Empty dependency array since it doesn't depend on any props or state

		// Memoize the subscription logic
		const subscribeToTopics = useCallback((mqttClient) => {
			if (!accountdevices) return;

			accountdevices
				.filter(device => device.udi)
				.forEach(device => {
					const topic = `interceptor/${device.udi}/#`;
					mqttClient.subscribe(topic, (err) => {
						if (err) console.error('Subscribe error:', err);
					});
				});
		}, [accountdevices]);

		useEffect(() => {
			if (client) return; // Skip if client already exists

			const mqttClient = mqtt.connect('wss://interceptor.skylab.nl:8883', {
				clientId: `client_${Math.random().toString(16).substring(2, 8)}`,
				keepalive: 30,
				clean: true,
				reconnectPeriod: 5000,
				connectTimeout: 30 * 1000,
				protocolVersion: 4,
				ssl: true,
			});

			mqttClient.on('connect', () => {
				setIsConnected(true);
				subscribeToTopics(mqttClient);
			});

			mqttClient.on('message', (topic, message) => {
				onMessageArrived({
					destinationName: topic,
					payloadString: message.toString()
				});
			});

			mqttClient.on('error', (err) => {
				console.error('MQTT Error:', err);
				setIsConnected(false);
			});

			mqttClient.on('close', () => {
				setIsConnected(false);
			});

			setClient(mqttClient);

			return () => {
				mqttClient.end();
			};
		}, []); // Empty dependency array since we only want to create the client once

		const interceptors = {};

		const [filterText, setFilterText] = useState('');
		const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
		const filteredItemsInt = datainterseptor.filter(
			item =>
				(item.udi && item.udi.toLowerCase().includes(filterText.trim().toLowerCase())) ||
				(item.devicename && item.devicename.toLowerCase().includes(filterText.trim().toLowerCase())) ||
				(item.seal && item.seal.toLowerCase().includes(filterText.trim().toLowerCase())),
		);

		const subHeaderComponentMemo = useMemo(() => {
			const handleClear = () => {
				if (filterText) {
					setResetPaginationToggle(!resetPaginationToggle);
					setFilterText('');
				}
			};

			return (
				<FilterComponentInterceptor onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
			);
		}, [filterText, resetPaginationToggle]);

		return (
			<>
				<DataTable
					columns={columns_Interceptor}
					data={filteredItemsInt}
					progressPending={loading}
					pagination
					paginationComponentOptions={paginationComponentOptions}
					conditionalRowStyles={conditionalRowStyles}
					expandableRows expandableRowsComponent={ExpandableRowComponent}
					subHeader
					subHeaderComponent={subHeaderComponentMemo}
					persistTableHead
					highlightOnHover
					customStyles={customStyles}
					fixedHeader={fixedHeader}
					fixedHeaderScrollHeight={fixedHeaderScrollHeight}
					dence={dence}
					paginationPerPage={20}
					defaultSortFieldId="Imei"
				/>
			</>
		);
	};

	const FilteringBLE_RT = (props) => {
		// Clear dataMac state on initial render
		const [dataMac, setDataMac] = useState([]);
		const [loading, setLoading] = useState(false);
		const [isConnectedRT, setIsConnectedRT] = useState(false);
		const [client, setClient] = useState(null);
		const interceptorsMacRef = useRef({});

		// Add useEffect to clear data when props.imei changes
		useEffect(() => {
			setDataMac([]); // Clear the data
			interceptorsMacRef.current = {}; // Clear the ref
		}, [props.imei]);

		const propsimei = props.imei;

		// Memoize the message handler
		const onMessageArrivedRT = useCallback(async (message) => {
			const topic = message.destinationName;
			const payload = message.payloadString;
			const parts = topic.split('/');

			// Only proceed if we have enough parts AND a MAC address is present
			if (parts.length >= 4) {  // Changed from 2 to 4 to ensure MAC address exists
				const imei = parts[1];
				const macAddress = parts[3];
				const dataType = parts[4];

				//Skip wrong mac for rt table
				if (imei !== propsimei) return;

				// Skip if MAC address is invalid length
				if (!macAddress || (macAddress.length !== 12 && macAddress.length !== 17)) {
					return;
				}

				try {
					// Check if mac is in the whitelist
					const response = await fetch('https://interceptor.skylab.nl:2807/whitelist', {
						method: 'GET',
						headers: {
							'Content-Type': 'application/json'
						},
					});

					const data = await response.json();
					// If MAC address is in whitelist, skip processing this message
					if (data.some(item => item.whitemac.toLowerCase() === macAddress.toLowerCase())) {
						if (interceptorsMacRef.current[imei]?.[macAddress]) {
							delete interceptorsMacRef.current[imei][macAddress];
							setDataMac(prevData => prevData.filter(row => row.mac.toLowerCase() !== macAddress.toLowerCase()));
						}
						return;
					}

					// Update interceptorsMac ref without triggering re-renders
					if (!interceptorsMacRef.current[imei]) {
						interceptorsMacRef.current[imei] = {};
					}
					if (!interceptorsMacRef.current[imei][macAddress]) {
						interceptorsMacRef.current[imei][macAddress] = {
							firstSeen: Date.now(),
							lastSeen: Date.now(),
							manufacturerInfo: []
						};
					} else {
						// Update lastSeen timestamp for existing entries
						interceptorsMacRef.current[imei][macAddress].lastSeen = Date.now();
					}

					// Update the data for this MAC address and type
					interceptorsMacRef.current[imei][macAddress][dataType] = payload;

					// Manufacturer lookup and array storage
					if (parts[4] === 'data') {
						const manufactCode = payload.substring(4, 2).toUpperCase() + payload.substring(0, 2).toUpperCase();
						const manufacturerMatch = manufacturers.find(m => m.company_code === manufactCode);
						const manufacturerName = manufacturerMatch ? manufacturerMatch.company : manufactCode;

						interceptorsMacRef.current[imei][macAddress].manufacturerInfo = [
							imei,
							macAddress,
							manufacturerName
						];

						const decoder = new AirTagDecoder(macAddress, payload);
						const decodedResults = decoder.decode();
						interceptorsMacRef.current[imei][macAddress].decodedResults = decodedResults[0].status;
					}

					// Batch update state
					setDataMac(Object.entries(interceptorsMacRef.current[imei])
						.map(([mac, dataparameters]) => {
							const insystemtime = Math.floor((Date.now() - dataparameters.firstSeen) / 1000);
							const regtime = Math.floor((dataparameters.lastSeen - dataparameters.firstSeen) / 1000);

							return {
								udi: imei,
								mac: mac || '-',
								name: dataparameters.name || '-',
								manufacturer: dataparameters.manufacturerInfo ? dataparameters.manufacturerInfo[2] : '-',
								rssi: parseInt(dataparameters.rssi) || 0,
								regtime: regtime,
								firstSeen: dataparameters.firstSeen,
								lastSeen: dataparameters.lastSeen,
								alarm: parseInt(dataparameters.alarm) || 0,
								status: parseInt(dataparameters.status) || 0,
								decodedData: dataparameters.decodedResults || null,
								insystemtime: insystemtime,
								differcetime: insystemtime - regtime  // Calculate difference between onscreen time and registration time
							};
						})
						.filter(row => row.rssi !== 0 || row.regtime <= 30)
						.sort((a, b) => a.regtime - b.regtime));

				} catch (error) {
					console.error('Error processing message:', error);
				}
			}
		}, [propsimei, manufacturers]); // Add dependencies

		// Check for stale data every 30 seconds
		useEffect(() => {
			const checkStaleData = () => {
				const currentTime = Date.now();
				setDataMac(prevData =>
					prevData.filter(row => {
						// Calculate time since last update in seconds
						const timeSinceLastUpdate = (currentTime - row.lastSeen) / 1000;
						// Keep row only if it was updated in the last 60 seconds
						return timeSinceLastUpdate <= 60;
					})
				);

				// Also clear the interceptorsMacRef data for consistency
				Object.keys(interceptorsMacRef.current).forEach(imei => {
					Object.keys(interceptorsMacRef.current[imei]).forEach(mac => {
						const timeSinceLastUpdate = (currentTime - interceptorsMacRef.current[imei][mac].lastSeen) / 1000;
						if (timeSinceLastUpdate > 60) {
							delete interceptorsMacRef.current[imei][mac];
						}
					});
					// Clean up empty imei objects
					if (Object.keys(interceptorsMacRef.current[imei]).length === 0) {
						delete interceptorsMacRef.current[imei];
					}
				});
			};

			const interval = setInterval(checkStaleData, 30000); // 30 seconds

			// Cleanup interval on component unmount
			return () => clearInterval(interval);
		}, []);


		// Update MQTT effect dependencies
		useEffect(() => {
			if (client) return;

			const mqttClient_RT = mqtt.connect('wss://interceptor.skylab.nl:8883', {
				clientId: `client_${Math.random().toString(16).substring(2, 8)}`,
				keepalive: 30,
				clean: true,
				reconnectPeriod: 5000,
				connectTimeout: 30 * 1000,
				protocolVersion: 4,
				ssl: true
			});

			mqttClient_RT.on('connect', () => {
				setIsConnectedRT(true);
				const topic = `interceptor/${propsimei}/#`;
				mqttClient_RT.subscribe(topic, (err) => {
					if (err) console.error('Subscribe failed:', err);
				});
			});

			mqttClient_RT.on('message', (topic, message) => {
				onMessageArrivedRT({
					destinationName: topic,
					payloadString: message.toString()
				});
			});

			mqttClient_RT.on('error', (err) => {
				console.error('MQTT Error:', err);
				setIsConnectedRT(false);
			});

			mqttClient_RT.on('close', () => {
				setIsConnectedRT(false);
			});

			setClient(mqttClient_RT);

			return () => {
				mqttClient_RT.end();
			};
		}, [propsimei, onMessageArrivedRT]); // Add onMessageArrivedRT as dependency

		const [filterText, setFilterText] = useState('');

		const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
		const filteredItemsMac = dataMac.filter(
			item =>
				(item.udi && item.udi.toLowerCase().includes(filterText.trim().toLowerCase())) ||
				(item.mac && item.mac.toLowerCase().includes(filterText.trim().toLowerCase())) ||
				(item.manufacturer && item.manufacturer.toLowerCase().includes(filterText.trim().toLowerCase())) ||
				(item.name && item.name.toLowerCase().includes(filterText.trim().toLowerCase())),
		);

		const subHeaderComponentMemo = useMemo(() => {
			const handleClear = () => {
				if (filterText) {
					setResetPaginationToggle(!resetPaginationToggle);
					setFilterText('');
				}
			};

			return (
				<>
					<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
				</>
			);
		}, [filterText, resetPaginationToggle]);

		return (
			<>
				<DataTable
					columns={columns_rt.map(col => {
						if (col.button) {
							return {
								...col,
								cell: (row) => col.cell(row, setDataMac)
							};
						}
						return col;
					})}
					data={filteredItemsMac}
					progressPending={loading}
					pagination
					paginationComponentOptions={paginationComponentOptions}
					conditionalRowStyles={conditionalRowStyles_rt}
					subHeader
					subHeaderComponent={subHeaderComponentMemo}
					persistTableHead
					customStyles={customStyles}
					fixedHeader={fixedHeader}
					fixedHeaderScrollHeight={fixedHeaderScrollHeight}
					dence={dence}
					paginationPerPage={10}
				/>
			</>
		);
	};

	const handleTabSelect = (index) => {
		setActiveTab(index);
		// Refresh account devices when dashboard tab (index 0) is selected
		if (index === 0) {
			// Force a re-fetch and ensure state updates
			fetchaccountdevices(accountid).then(() => {
				// Optionally force a re-render or update other dependent states
				fetchudi(); // Also refresh UDI content if needed
			}).catch(error => {
				console.error('Error refreshing account devices:', error);
			});
		}
	};

	return (
		<>
			<div className="cDashboard">
				<header className="App-header">
					Interceptor V2.0 SSL - {user}
					<Button className='cButtonRight' variant="warning"
						onClick={(e) => {
							logout();
						}}>
						Log off
					</Button>
				</header>

				<Tabs selectedIndex={activeTab} onSelect={handleTabSelect}>
					<TabList>
						<Tab>Realtime</Tab>
						<Tab>RT Map</Tab>
						<Tab>Multi RT Map</Tab>
						<Tab>History Map</Tab>
						<Tab>Devices chart</Tab>
						<Tab disabled={!isadmin}>Administration</Tab>
						<Tab disabled={true}>Follow MAC</Tab>
					</TabList>

					<TabPanel>	{/* Realtime */}
						<FilteringInterceptor></FilteringInterceptor>
					</TabPanel>
					<TabPanel>	{/* Realtime map*/}
						<Maprt></Maprt>
					</TabPanel>
					<TabPanel>	{/* MultiMap*/}
						<MultiMap></MultiMap>
					</TabPanel>
					<TabPanel>	{/* map */}
						<Historymap></Historymap>
					</TabPanel>
					<TabPanel>	{/* barchart */}
						<BarChart></BarChart>
					</TabPanel>
					<TabPanel>	{/* Administrator */}
						<Tabs>
							<TabList>
								<Tab>Account</Tab>
								<Tab>Interceptor</Tab>
								<Tab>Whitelist</Tab>
								<Tab>Group</Tab>
								<Tab>Change passwords</Tab>
								{/* <Tab>Alert</Tab> */}
								<Tab>Broker status</Tab>
							</TabList>

							<TabPanel>	{/* accounts */}
								<Account></Account>
							</TabPanel>

							<TabPanel>	{/* Interceptor */}
								<Interceptor></Interceptor>
							</TabPanel>

							<TabPanel>	{/* Whitelist */}
								<Whitelist></Whitelist>
							</TabPanel>

							<TabPanel>	{/* User Interceptor Groups */}
								<Groups></Groups>
							</TabPanel>

							<TabPanel>	{/* User Change Password */}
								<Passwordchange></Passwordchange>
							</TabPanel>

							<TabPanel>	{/* Broker status */}
								<Brokerstatus></Brokerstatus>
							</TabPanel>
						</Tabs>
					</TabPanel>
					<TabPanel>	{/* Follow MAC */}
						<Followmac></Followmac>
					</TabPanel>
				</Tabs>
			</div>
		</>
	);
}